<template>
  <div
    class="header-global-wrapper mega-menu"
    style="z-index: 6"
    @mouseleave="showMegaMenu = false"
  >
    <v-navigation-drawer
      v-if="$vuetify.breakpoint.mobile"
      v-model="showMegaMenu"
      style="padding-top: max(0px, env(safe-area-inset-top))"
      absolute
      temporary
    >
      <v-list
        nav
        dense
      >
        <v-list-item to="/">
          <v-list-item-icon>
            <v-icon>mdi-home</v-icon>
          </v-list-item-icon>

          <v-list-item-title>Home</v-list-item-title>
        </v-list-item>

        <v-list-group
          v-for="list in navigationItems"
          :key="list"
          :value="true"
          class="mb-4 navigation-list"
          color="var(--highlightColor)"
        >
          <template #activator>
            <v-list-item-title>
              {{ ($root.taskLists.find(l => l.uuid === list) || { name: 'Unknown List' }).name }}
            </v-list-item-title>
          </template>
          <div
            v-for="item in listContents(list)"
            :key="item.uuid"
          >
            <v-list-item
              v-if="item.type === 'task'"
              color="var(--highlightColor)"
              style="margin-bottom: 0 !important;"
              :to="item.material ? ('/materials/' + $root.materialIDToPathMap[item.uuid]) : '/' + $root.publicTaskIDToPathMap[item.uuid]"
            >
              <v-list-item-title class="pl-4">
                {{ taskName(item.task) }}
              </v-list-item-title>
            </v-list-item>

            <v-list-group
              v-else-if="item.type === 'subcategory'"
              sub-group
              class="mb-2 navigation-list-subgroup"
              color="var(--highlightColor)"
              style="min-height: 40px;"
            >
              <template #activator>
                <v-list-item-title>
                  {{ item.name }}
                </v-list-item-title>
              </template>
              <v-list-item
                v-for="subtask in item.tasks"
                :key="subtask.uuid"
                color="var(--highlightColor)"
                style="margin-bottom: 0 !important; min-height: 30px;"
                :to="subtask.material ? ('/materials/' + $root.materialIDToPathMap[subtask.uuid]) : '/' + $root.publicTaskIDToPathMap[subtask.uuid]"
              >
                <v-list-item-title class="pl-4">
                  {{ taskName(subtask) }}
                </v-list-item-title>
              </v-list-item>
            </v-list-group>
          </div>
        </v-list-group>

        <v-list-item
          v-if="$root.enterprise.enableStore"
          to="/products"
          class="pl-4"
        >
          <v-list-item-title>Filament Store</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-slide-y-transition v-if="!$vuetify.breakpoint.mobile">
      <div
        v-if="showMegaMenu"
        id="mega-menu"
        style="position: absolute; top: 50px; width: 100%; background-color: var(--paneColor1); justify-content: space-around; box-shadow: 0 20px 20px -20px var(--shadowColor); z-index: 10;"
        class="d-flex"
      >
        <div style="width: 1000px;">
          <v-row v-if="megaMenuTopic === 'materials'">
            <v-col :cols="$vuetify.breakpoint.mobile ? 12 : 5">
              <div class="d-flex">
                <div
                  v-for="tech in (($root.catalog || []).find(x => x.name === 'Technology') || {}).children.filter(x => !x.advanced && x.active)"
                  :key="tech.uuid"
                  class="mx-1"
                  style="width: 100%"
                >
                  <v-list-item
                    :to="'/' + $root.publicTaskIDToPathMap[tech.task]"
                    style="border-radius: 12px; overflow: hidden;"
                    class="mr-n2"
                  >
                    <v-list-item-content class="pb-1 pt-2">
                      <v-card-title class="text--secondary pa-0">
                        {{ tech.displayName }}
                      </v-card-title>
                      <h5
                        style="font-weight: normal"
                        class="text--secondary mb-2"
                      >
                        {{ tech.shortDescription }}
                      </h5>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider class="ml-4" />
                  <v-list-item
                    v-for="mat in ((($root.catalog || []).find(x => x.name === 'Material') || {}).children || []).filter(m => !m.specialty && !m.advanced && m.restrictions && m.restrictions.Technology && m.restrictions.Technology.includes(tech.name))"
                    :key="mat.uuid"
                    dense
                    style="width: 100%; border-radius: 32px; overflow: hidden;"
                    :to="'/materials/' + $root.materialIDToPathMap[mat.uuid]"
                    @mouseenter="materialHover = mat"
                  >
                    <v-icon
                      v-if="mat.starred"
                      size="12"
                      left
                      color="amber"
                    >
                      fas fa-star
                    </v-icon>{{ mat.displayName }}
                  </v-list-item>
                </div>
              </div>
              <div
                class="d-flex pl-16 pr-8"
                style="width: 100%; justify-content: center;"
              >
                <div style="flex-grow: 1">
                  <v-btn
                    to="/material-guide"
                    color="var(--highlightColor)"
                    rounded
                    dark
                    block
                    class="my-4"
                  >
                    <v-icon
                      left
                      small
                    >
                      fas fa-cube
                    </v-icon>
                    Material Guide
                  </v-btn>
                </div>
              </div>
            </v-col>
            <v-col
              :cols="$vuetify.breakpoint.mobile ? 12 : 7"
              class="pl-16"
            >
              <div v-if="materialHover">
                <h1
                  class="mt-2"
                  style="color: var(--highlightColor)"
                >
                  {{ materialHover.name }}
                </h1>
                <h3
                  class="mb-2"
                  style="opacity: 0.9"
                >
                  {{ materialHover.description }}
                </h3>
                <p class="mb-2">
                  {{ materialHover.longDescription }}
                </p>
                <v-row class="mt-4">
                  <v-col cols="7">
                    <div
                      v-if="materialHover.images"
                      class="mb-4"
                    >
                      <v-img
                        v-for="(img, i) in materialHover.images.slice(0, 1)"
                        :key="i"
                        contain
                        height="200"
                        max-width="300"
                        :src="$root.shrunkImage(img.url, 300)"
                        :lazy-src="$root.shrunkImage(img.url, 50)"
                      />
                    </div>
                  </v-col>
                  <v-col cols="5">
                    <div
                      v-for="u in (materialHover.sellingPoints || []).filter(key => $root.sellingPoints[key])"
                      :key="u"
                      class="d-flex my-1"
                    >
                      <v-img
                        max-width="20"
                        height="20"
                        :src="$root.shrunkImage($root.sellingPoints[u].image, 20)"
                        contain
                        class="mr-3"
                        style="width: 20px; margin-top: 1px;"
                      />
                      <p style="flex-grow: 1">
                        {{ $root.sellingPoints[u].name }}
                      </p>
                    </div>
                  </v-col>
                </v-row>
              </div>
            </v-col>
          </v-row>
          <v-row v-else-if="megaMenuTopic === 'store'">
            <v-col :cols="$vuetify.breakpoint.mobile ? 12 : 5">
              <div
                class="d-flex flex-column"
                style="flex-wrap: wrap; max-height: 380px;"
              >
                <div
                  v-for="cat in categories.filter(cat => activeCategoryProducts(cat.id).length)"
                  :key="cat.id"
                  class="mx-1"
                  style="width: 50%"
                >
                  <v-list-item
                    dense
                    color="var(--highlightColor) !important"
                    class="py-1 text--secondary mt-2 pr-0"
                    style="width: 100%; border-radius: 32px; overflow: hidden; font-size: 19px; font-weight: bold;"
                    :to="'/products/' + cat.path"
                    exact-path
                  >
                    {{ cat.name }}
                    <v-icon class="ml-1">
                      mdi-chevron-right
                    </v-icon>
                  </v-list-item>
                  <v-divider class="ml-4" />
                  <v-list-item
                    v-for="p in activeCategoryProducts(cat.id)"
                    :key="p"
                    dense
                    style="width: 100%; border-radius: 32px; overflow: hidden;"
                    :to="'/products/' + products[p].categoryPath + '/' + products[p].path"
                    @mouseenter="productHover = products[p]"
                  >
                    {{ products[p].name }}
                  </v-list-item>
                </div>
              </div>
              <div
                class="d-flex pl-16 pr-8"
                style="width: 100%; justify-content: center;"
              >
                <div style="flex-grow: 1">
                  <v-btn
                    to="/products"
                    color="var(--highlightColor)"
                    rounded
                    dark
                    block
                    class="mt-8 mb-4"
                  >
                    <v-icon
                      left
                      small
                    >
                      fas fa-store
                    </v-icon>
                    All Filaments
                  </v-btn>
                </div>
              </div>
            </v-col>
            <v-col
              :cols="$vuetify.breakpoint.mobile ? 12 : 7"
              class="pl-16"
            >
              <div v-if="productHover">
                <div class="d-flex">
                  <div>
                    <div class="d-flex mb-2">
                      <v-img
                        v-if="storeStyle === 1 && productHover.variants.length && productHover.variantData[productHover.variants[0]]"
                        style="border-radius: 16px; max-width"
                        height="84"
                        width="84"
                        max-width="84"
                        class="mr-4 my-2"
                        contain
                        :src="$root.shrunkImage(productHover.variantData[productHover.variants[0]].media[0], 300)"
                        :lazy-src="$root.shrunkImage(productHover.variantData[productHover.variants[0]].media[0].banner, 50)"
                      />
                      <div style="flex-grow: 1">
                        <h2
                          class="mt-2"
                          style="color: var(--highlightColor)"
                        >
                          {{ productHover.name }}
                        </h2>
                        <h3
                          class="mb-2"
                          style="opacity: 0.9"
                        >
                          {{ productHover.shortDescription }}
                        </h3>
                      </div>
                    </div>
                    <div style="min-height: 115px;">
                      <p class="mb-2">
                        {{ productHover.applications || productHover.description }}
                      </p>
                    </div>
                  </div>
                </div>
                <div class="d-flex mb-4">
                  <v-img
                    v-if="storeStyle === 2 && productHover.variants.length && productHover.variantData[productHover.variants[0]]"
                    style="border-radius: 16px; max-width"
                    height="170"
                    width="170"
                    max-width="170"
                    class="mr-4 mt-4"
                    contain
                    :src="$root.shrunkImage(productHover.variantData[productHover.variants[0]].media[0], 300)"
                    :lazy-src="$root.shrunkImage(productHover.variantData[productHover.variants[0]].media[0].banner, 50)"
                  />
                  <div
                    style="height: 200px; flex-grow: 1;"
                    :class="{
                      'mr-0': storeStyle === 1,
                    }"
                  >
                    <material-radar-chart
                      v-if="productHover.isMaterial"
                      :product="productHover"
                    />
                  </div>
                </div>
              </div>
            </v-col>
          </v-row>
          <v-row
            v-else-if="($root.enterprise.taskLists.find(x => x.uuid === megaMenuTopic) || {}).megaMenuGallery"
            class="d-flex"
            style="justify-content: center; width: 100%;"
          >
            <v-list-item
              v-for="task in $root.publicTasks.filter(x => x.list === megaMenuTopic).sort((a, b) => (+a.readerSortOrder || Infinity) - (+b.readerSortOrder || Infinity))"
              :key="task.uuid"
              :to="'/' + $root.publicTaskIDToPathMap[task.uuid]"
              class="my-8 mx-4"
              style="border-radius: 32px; width: 200px; max-width: 200px; overflow: hidden;"
            >
              <v-list-item-content
                style="max-height: 100%;"
                class="py-8"
              >
                <div
                  v-if="task.icon"
                  style="text-align: center; height: 70px;"
                >
                  <v-icon
                    v-if="task.icon"
                    size="60"
                  >
                    {{ task.icon }}
                  </v-icon>
                </div>
                <v-img
                  v-else
                  width="240"
                  height="180"
                  style="border-radius: 16px; overflow: hidden;"
                  contain
                  :src="$root.shrunkImage((primaryImage(task) || {}).url, 240)"
                />
                <h4
                  class="text--secondary mt-2"
                  style="width: 240px; text-align: center;"
                >
                  {{ primaryElementText(task) }}
                </h4>
              </v-list-item-content>
            </v-list-item>
          </v-row>
          <div
            v-else-if="$root.enterprise.taskLists.find(x => x.uuid === megaMenuTopic)"
            class="d-flex"
          >
            <div
              v-for="(col, c) in listLayout($root.enterprise.taskLists.find(x => x.uuid === megaMenuTopic)) || []"
              :key="c"
              style="min-width: 200px; display: flex; flex-direction: column;"
            >
              <div
                v-for="(elem, e) in col"
                :key="e"
              >
                <div v-if="elem.link">
                  <v-list-item
                    style="border-radius: 100px; overflow: hidden;"
                    :to="elem.link"
                    :class="{ 'mb-12': elem.endOfList }"
                    @mouseenter="$set(imageHover, megaMenuTopic, elem.image || '')"
                  >
                    <template #default="{ active }">
                      <span
                        class="ml-2"
                        :class="{ 'text--secondary': elem.bold ? 'bold' : 'normal' }"
                        :style="{ fontWeight: elem.bold ? 'bold' : 'normal' }"
                      >{{ elem.text }}</span>
                      <v-spacer />
                      <v-icon
                        v-if="active"
                        small
                        color="var(--highlightColor)"
                      >
                        fas fa-chevron-right
                      </v-icon>
                    </template>
                  </v-list-item>
                  <v-spacer />
                </div>
                <div
                  v-else
                  class="ml-6"
                >
                  <h4 class="mb-2 text--secondary">
                    {{ elem.text }}
                  </h4>
                  <v-divider />
                </div>
              </div>
            </div>
            <div style="position: relative; flex-grow: 1;">
              <div style="height: 100%; width: 100%; position: absolute; padding: 8px; display: flex; flex-direction: column; justify-content: center;">
                <div style="max-height: 100%; width: 100%; display: flex; justify-content: center;">
                  <img
                    style="height: 100%; object-fit: contain; overflow: hidden; border-radius: 16px; display: block;"
                    :alt="($root.enterprise.taskLists.find(x => x.uuid === megaMenuTopic) || {}).name"
                    :lazy-src="$root.shrunkImage(((imageHover[megaMenuTopic] || ($root.publicTasks.find(x => x.list === megaMenuTopic) ? primaryImage($root.publicTasks.find(x => x.list === megaMenuTopic)) : {})) || {}).url, 100)"
                    :src="$root.shrunkImage(((imageHover[megaMenuTopic] || ($root.publicTasks.find(x => x.list === megaMenuTopic) ? primaryImage($root.publicTasks.find(x => x.list === megaMenuTopic)) : {})) || {}).url, 1000)"
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </v-slide-y-transition>

    <div v-if="$root.currentUser && ($root.isStaff || $root.isAlumni)">
      <div class="header">
        <v-btn
          v-if="$vuetify.breakpoint.xs"
          text
          icon
          width="42"
          height="42"
          class="my-1 ml-2"
          style="text-decoration: none;"
          :dark="$root.lightHeaderText"
          :style="{ transform: $root.showStaffSideBar ? 'rotate(-90deg)' : '' }"
          @click="$root.showStaffSideBar = !$root.showStaffSideBar"
        >
          <v-icon>
            fas fa-bars
          </v-icon>
        </v-btn>

        <app-logo v-if="!$vuetify.breakpoint.xs" />

        <div
          v-if="$root.isStaff"
          id="search-wrapper"
          :style="{ minWidth: showResults && $vuetify.breakpoint.mobile ? 'calc(100% - 70px)' : '0px' }"
        >
          <div
            style="font-size: 16px; width: 100%; min-height: 34px; position: relative;"
            :style="{ backgroundColor: $root.lightHeaderText ? '#FFF2' : '#0001' }"
          >
            <v-text-field
              id="search-query"
              v-model="searchQuery"
              :placeholder="!$vuetify.breakpoint.xs || showResults ? 'Search' : ''"
              hide-details
              :loading="searching"
              onfocus="this.select()"
              style="width: 100%; padding-top: 1px;"
              class="mt-0"
              :dark="$root.lightHeaderText"
              color="var(--highlightColor)"
              @focus="showResults = true"
            >
              <template #prepend-inner>
                <v-icon
                  size="16"
                  style="padding-top: 5px; padding-left: 12px; padding-bottom: 8px; padding-right: 4px;"
                >
                  fas fa-search
                </v-icon>
              </template>
            </v-text-field>
            <div
              v-if="showResults"
              style="font-size: 14px; transition: 0.2s; border-radius: 4px;"
              class="pb-2"
            >
              <v-chip-group
                v-model="searchCategory"
                mandatory
                style="margin-left: 10px"
                :dark="$root.lightHeaderText"
              >
                <v-chip
                  small
                  value="projects"
                  color="var(--headerColor)"
                  :text-color="$root.lightHeaderText ? 'white' : 'grey'"
                >
                  Projects
                </v-chip>
                <v-chip
                  small
                  value="users"
                  color="var(--headerColor)"
                  :text-color="$root.lightHeaderText ? 'white' : 'grey'"
                >
                  Users
                </v-chip>
                <v-chip
                  small
                  value="organizations"
                  color="var(--headerColor)"
                  :text-color="$root.lightHeaderText ? 'white' : 'grey'"
                >
                  Organizations
                </v-chip>
                <v-chip
                  small
                  value="chats"
                  color="var(--headerColor)"
                  :text-color="$root.lightHeaderText ? 'white' : 'grey'"
                >
                  Tasks
                </v-chip>
              </v-chip-group>
            </div>
            <div class="mt-n4 pb-2">
              <div
                v-if="showResults && searchCategory === 'projects'"
                id="search-results"
              >
                <project-card
                  v-for="project in projectSearchResults"
                  :key="project.objectID"
                  :ref="projectHighlightedResult == project ? 'highlightedResult' : ''"
                  class="project-wrapper"
                  draggable="true"
                  show-status
                  ondragstart="startDraggingCard(event)"
                  ondragend="endDraggingCard()"
                  :uuid="project.uuid"
                  :class="{ active: projectHighlightedResult == project }"
                  :project="project"
                  @click.native="showResults = false"
                  @mouseover="projectHighlightedResult = project"
                />
              </div>
              <div
                v-if="showResults && searchCategory === 'users'"
                id="search-results"
                class="py-2"
              >
                <user-card
                  v-for="user in userSearchResults"
                  :key="user.objectID"
                  :ref="userHighlightedResult == user ? 'highlightedResult' : ''"
                  :user="user"
                  :class="{ active: userHighlightedResult == user }"
                  @mouseover="userHighlightedResult = project"
                  @click.native="showResults = false"
                />
              </div>
              <div
                v-if="showResults && searchCategory === 'organizations'"
                id="search-results"
                class="py-2"
              >
                <organization-card
                  v-for="organization in organizationSearchResults"
                  :key="organization.objectID"
                  :ref="organizationHighlightedResult == organization ? 'highlightedResult' : ''"
                  :organization="organization"
                  :uuid="organization.objectID"
                  :class="{ active: organizationHighlightedResult == organization }"
                  @mouseover="organizationHighlightedResult = project"
                  @click.native="showResults = false"
                />
              </div>
              <div
                v-if="showResults && searchCategory === 'chats'"
                id="search-results"
                class="pt-2 px-4"
              >
                <task
                  v-for="task in chatSearchResults"
                  :key="task.objectID"
                  :ref="taskHighlightedResult == task ? 'highlightedResult' : ''"
                  class="my-2"
                  style="border-radius: 8px !important"
                  :task="task"
                  :uuid="task.objectID"
                  :class="{ active: chatHighlightedResult == task }"
                  @mouseover="chatHighlightedResult = task"
                  @click.native="$root.notificationClicked({ task: task.objectID }); showResults = false;"
                />
              </div>
            </div>
          </div>
        </div>

        <div
          class="header-right"
          style="position: relative;"
        >
          <v-menu
            v-model="qrScannerOpen"
            offset-y
            bottom
            :close-on-content-click="false"
            content-class="elevation-0 pa-1"
          >
            <template #activator="{ on, attrs }">
              <v-btn
                v-show="$root.isStaff && !$vuetify.breakpoint.mobile && $root.configPublic.enableHeaderQRScannerLink"
                icon
                text
                v-bind="attrs"
                width="42"
                height="42"
                class="my-1 ml-n1"
                :dark="$root.lightHeaderText"
                v-on="on"
              >
                <v-icon>
                  fas fa-qrcode
                </v-icon>
              </v-btn>
            </template>
            <v-card
              class="ma-0 pa-0"
              width="320px"
            >
              <video
                ref="scanner"
                style="width: 100%; display: block;"
              />
              <v-card
                v-if="qrScannerError || qrScannerLoading"
                class="ma-0 pa-0 d-flex flex-column justify-center align-center"
                width="320px"
                style="position: absolute; top: 0; left: 0; width: 100%; height: 100%; background-color: rgba(0, 0, 0, 0.75); z-index: 1"
              >
                <v-card-title
                  v-if="qrScannerError"
                  class="pa-2"
                  style="color: white;"
                >
                  <v-icon
                    class="mr-2"
                    color="white"
                  >
                    fas fa-exclamation-triangle
                  </v-icon>
                  <span>
                    {{ qrScannerError }}
                  </span>
                </v-card-title>
                <v-progress-circular
                  v-if="qrScannerLoading"
                  indeterminate
                  color="white"
                  size="36"
                />
              </v-card>
            </v-card>
          </v-menu>

          <v-img
            v-if="$root.holiday === 'aprilfools' && $root.userProfile && $root.userProfile.prefs_brand === 'lana'"
            :src="$root.shrunkImage('https://firebasestorage.googleapis.com/v0/b/formfactories-incept3d.appspot.com/o/media%2Fe59e9b44-ca86-4cd8-bb50-d861e980f4bd%2Fbrazil.png?alt=media&token=a9f92e56-b800-4568-bcae-d35999669726', null, 50)"
            height="50"
            max-width="160px"
            cover
            style="opacity: 0.8; position: absolute; left: 36px; z-index: 0"
          />

          <v-img
            v-if="$root.holiday === 'leogreencard' && $root.userProfile && $root.userProfile.prefs_brand === 'lana'"
            :src="require('@/assets/usa.png')"
            height="50"
            max-width="160px"
            cover
            style="opacity: 0.8; position: absolute; left: 36px; z-index: 0"
          />

          <v-dialog
            v-model="qrScannerOpen"
            fullscreen
            hide-overlay
            transition="dialog-bottom-transition"
          >
            <template #activator="{ on, attrs }">
              <v-btn
                v-show="$root.isStaff && $vuetify.breakpoint.mobile && $root.configPublic.enableHeaderQRScannerLink"
                icon
                text
                v-bind="attrs"
                width="42"
                height="42"
                class="my-1 ml-n1"
                :dark="$root.lightHeaderText"
                v-on="on"
              >
                <v-icon>
                  fas fa-qrcode
                </v-icon>
              </v-btn>
            </template>
            <v-card>
              <v-toolbar
                :dark="$root.lightHeaderText"
                color="var(--highlightColor)"
              >
                <v-btn
                  icon
                  :dark="$root.lightHeaderText"
                  @click="qrScannerOpen = false"
                >
                  <v-icon>mdi-close</v-icon>
                </v-btn>
                <v-toolbar-title>Scan Code</v-toolbar-title>
              </v-toolbar>
              <v-card elevation="0">
                <video
                  ref="scanner"
                  style="display: block; width: 100%;"
                />
              </v-card>
            </v-card>
          </v-dialog>

          <v-tooltip
            v-if="$root.isStaff && $root.configPublic.enableHeaderNewProjectLink"
            bottom
            open-delay="800"
          >
            <template #activator="{ on, attrs }">
              <v-btn
                icon
                text
                width="42"
                height="42"
                class="my-1"
                :loading="creatingProject"
                :dark="$root.lightHeaderText"
                v-bind="attrs"
                v-on="on"
                @click="createProject"
              >
                <v-icon>
                  fas fa-plus
                </v-icon>
              </v-btn>
            </template>
            Create New Project
          </v-tooltip>

          <v-spacer />

          <v-menu
            v-if="$root.management && !$vuetify.breakpoint.mobile && $root.configPublic.enableHeaderMachinesLink"
            v-model="printersOpen"
            offset-y
            bottom
            :close-on-content-click="false"
            :close-on-click="!$root.machinePanelOpen"
            content-class="elevation-0 pa-1"
          >
            <template #activator="{ on, attrs }">
              <v-btn
                text
                rounded
                v-bind="attrs"
                height="42"
                :width="$root.configPublic.enableHeaderMachineCount ? '' : '42'"
                class="my-1"
                :icon="!$root.configPublic.enableHeaderMachineCount"
                :dark="$root.lightHeaderText"
                v-on="on"
              >
                <span
                  v-if="$root.configPublic.enableHeaderMachineCount"
                  class="ml-n2 mr-n4"
                >
                  <v-icon
                    left
                    :color="$root.lightHeaderText ? '' : 'grey darken-1'"
                  >
                    mdi-printer-3d
                  </v-icon>
                  <span>{{ $root.machines.map(x => (x.instances || []).filter(x => !x.inoperable).length).reduce((a, b) => a + b, 0) }} / {{ $root.machines.map(x => parseInt(x.instances.length) || 0).reduce((a, b) => a + b, 0) }}</span>
                </span>
                <v-icon v-else>
                  mdi-printer-3d
                </v-icon>
              </v-btn>
            </template>
            <v-card
              class="ma-0"
              width="450px"
              max-height="90vh"
              style="overflow: auto"
            >
              <v-card-title class="px-6 py-4">
                <div style="display: flex; flex-direction: column;">
                  <div class="d-flex">
                    <span>All Machines</span>
                    <v-dialog
                      width="1000"
                    >
                      <template #activator="{ on: dialog }">
                        <v-tooltip top>
                          <template #activator="{ on: tooltip }">
                            <v-hover v-slot="{ hover }">
                              <v-btn
                                fab
                                class="ml-1"
                                text
                                style="margin-top: -6px;"
                                small
                                :color="hover ? 'var(--highlightColor)' : ($root.darkMode ? 'grey darken-1' : 'grey lighten-1')"
                                v-on="{ ...tooltip, ...dialog }"
                              >
                                <v-icon>mdi-pencil</v-icon>
                              </v-btn>
                            </v-hover>
                          </template>
                          <span>Edit Machines</span>
                        </v-tooltip>
                      </template>
                      <v-card>
                        <machines />
                      </v-card>
                    </v-dialog>
                  </div>
                  <v-checkbox
                    v-model="hideOperationalPrinters"
                    dense
                    class="mb-n5 mt-n1 ml-n1 mr-1"
                    label="Hide Operational"
                  />
                </div>
                <v-spacer />
                <v-btn
                  dark
                  right
                  color="var(--highlightColor)"
                  class="mr-1"
                  @click="$root.printerSnapshotBrowser = !$root.printerSnapshotBrowser"
                >
                  <div class="mx-n4">
                    <v-icon
                      small
                      left
                    >
                      fas fa-eye
                    </v-icon>
                    <span>{{ $root.configPublic.enableCustomMachineDashboardLink ? 'EYES' : 'Camera Feed' }} </span>
                  </div>
                </v-btn>
                <v-btn
                  v-if="$root.configPublic.enableCustomMachineDashboardLink"
                  dark
                  right
                  color="var(--highlightColor)"
                  :href="$root.configPublic.customMachineDashboardLink"
                  target="_blank"
                >
                  <span>{{ $root.configPublic.customMachineDashboardLinkText }}</span>
                  <v-icon
                    small
                    right
                  >
                    fas fa-external-link-alt
                  </v-icon>
                </v-btn>
              </v-card-title>
              <printer-menu
                :visible="printersOpen"
                :hide-operational="hideOperationalPrinters"
              />
            </v-card>
          </v-menu>

          <v-btn
            v-if="$root.enableTaskReader && $root.totalReaderTasks && $root.configPublic.enableHeaderGuideLink"
            icon
            text
            width="42"
            height="42"
            class="my-1"
            :dark="$root.lightHeaderText"
            @click="$root.showTaskReader = true"
          >
            <v-icon>
              fas fa-book-open
            </v-icon>
          </v-btn>

          <v-dialog
            v-if="$root.management && $vuetify.breakpoint.mobile && $root.configPublic.enableHeaderMachinesLink"
            v-model="printersOpen"
            fullscreen
            hide-overlay
            transition="dialog-bottom-transition"
          >
            <template #activator="{ on, attrs }">
              <v-btn
                icon
                text
                v-bind="attrs"
                width="42"
                height="42"
                class="my-1"
                :dark="$root.lightHeaderText"
                v-on="on"
              >
                <v-icon>
                  mdi-printer-3d
                </v-icon>
              </v-btn>
            </template>
            <v-card>
              <v-toolbar
                :dark="$root.lightHeaderText"
                color="var(--highlightColor)"
              >
                <v-btn
                  icon
                  :dark="$root.lightHeaderText"
                  @click="printersOpen = false"
                >
                  <v-icon>mdi-close</v-icon>
                </v-btn>
                <v-toolbar-title>
                  <span>All Printers</span>
                  <v-dialog
                    width="1000"
                  >
                    <template #activator="{ on: dialog }">
                      <v-tooltip bottom>
                        <template #activator="{ on: tooltip }">
                          <v-btn
                            fab
                            class="ml-1"
                            text
                            style="margin-top: -6px;"
                            small
                            color="white"
                            v-on="{ ...tooltip, ...dialog }"
                          >
                            <v-icon>mdi-pencil</v-icon>
                          </v-btn>
                        </template>
                        <span>Edit Printers</span>
                      </v-tooltip>
                    </template>
                    <v-card>
                      <machines />
                    </v-card>
                  </v-dialog>
                </v-toolbar-title>
                <v-spacer />
                <v-checkbox
                  v-model="hideOperationalPrinters"
                  class="mb-n5"
                  label="Hide Operational"
                />

                <v-spacer />
                <v-toolbar-items>
                  <v-btn
                    :dark="$root.lightHeaderText"
                    text
                    @click="$root.printerSnapshotBrowser = !$root.printerSnapshotBrowser"
                  >
                    <v-icon
                      small
                      left
                    >
                      fas fa-eye
                    </v-icon>
                    <span>{{ $root.configPublic.enableCustomMachineDashboardLink ? 'EYES' : 'Camera Feed' }}</span>
                  </v-btn>
                  <v-btn
                    :dark="$root.lightHeaderText"
                    text
                    href="http://fleet.markoritachka.com/"
                    target="_blank"
                  >
                    <span>Go To Fleet</span>
                    <v-icon
                      small
                      right
                    >
                      fas fa-external-link-alt
                    </v-icon>
                  </v-btn>
                </v-toolbar-items>
              </v-toolbar>
              <printer-menu
                :visible="printersOpen"
                :hide-operational="hideOperationalPrinters"
              />
            </v-card>
          </v-dialog>

          <v-menu
            v-if="!$vuetify.breakpoint.mobile"
            v-model="$root.showNotifications"
            :close-on-content-click="false"
            :close-on-click="!showPopTask"
            content-class="elevation-0 pa-1"
            :nudge-width="200"
            offset-y
            bottom
          >
            <template #activator="{ on, attrs }">
              <v-badge
                v-if="$root.isStaff"
                color="red"
                offset-x="22px"
                offset-y="24px"
                :content="notificationBadgeContent"
                :value="!!notificationBadgeContent"
              >
                <v-btn
                  icon
                  text
                  v-bind="attrs"
                  width="42"
                  height="42"
                  class="my-1"
                  :dark="$root.lightHeaderText"
                  v-on="on"
                >
                  <v-icon>
                    fas fa-bell
                  </v-icon>
                </v-btn>
              </v-badge>
            </template>

            <v-card
              width="480px"
              class="ma-0"
            >
              <notifications style="max-height: 80vh; display: flex; flex-direction: column; overflow: hidden;" />
            </v-card>
          </v-menu>

          <v-menu
            v-if="!$vuetify.breakpoint.mobile && $root.configPublic.enableHeaderTasksLink"
            v-model="$root.showTasks"
            :close-on-content-click="false"
            content-class="elevation-0 pa-1"
            :close-on-click="!showPopTask && !$root.machinePanelOpen"
            :nudge-width="200"
            offset-y
            bottom
          >
            <template #activator="{ on, attrs }">
              <v-badge
                v-if="$root.isStaff"
                :color="$root.lightHeaderText ? 'white' : 'grey'"
                offset-x="18px"
                offset-y="22px"
                class="tasks-badge"
                :value="!!$root.tasksLeft"
                :content="$root.tasksLeft"
              >
                <v-btn
                  icon
                  text
                  v-bind="attrs"
                  width="42"
                  height="42"
                  class="my-1"
                  :dark="$root.lightHeaderText"
                  v-on="on"
                >
                  <v-icon>
                    fas fa-tasks
                  </v-icon>
                </v-btn>
              </v-badge>
            </template>

            <v-card
              width="850px"
              class="ma-0"
            >
              <tasks style="height: 80vh; overflow: hidden;" />
            </v-card>
          </v-menu>

          <v-dialog
            v-if="$root.isStaff && $vuetify.breakpoint.mobile"
            v-model="$root.showNotifications"
            fullscreen
            hide-overlay
            transition="dialog-bottom-transition"
          >
            <template #activator="{ on, attrs }">
              <v-badge
                color="red"
                offset-x="20px"
                offset-y="24px"
                :content="notificationBadgeContent"
                :value="!!notificationBadgeContent"
              >
                <v-btn
                  icon
                  text
                  v-bind="attrs"
                  width="42"
                  height="42"
                  class="my-1"
                  :dark="$root.lightHeaderText"
                  v-on="on"
                >
                  <v-icon>
                    fas fa-bell
                  </v-icon>
                </v-btn>
              </v-badge>
            </template>
            <v-card>
              <v-toolbar
                :dark="$root.lightHeaderText"
                color="var(--highlightColor)"
              >
                <v-btn
                  icon
                  :dark="$root.lightHeaderText"
                  @click="$root.showNotifications = false"
                >
                  <v-icon>mdi-close</v-icon>
                </v-btn>
                <v-toolbar-title>Notifications</v-toolbar-title>
              </v-toolbar>
              <notifications style="display: flex; flex-direction: column; overflow: hidden;" />
            </v-card>
          </v-dialog>

          <v-dialog
            v-if="$root.isStaff && $vuetify.breakpoint.mobile && $root.configPublic.enableHeaderTasksLink"
            v-model="$root.showTasks"
            fullscreen
            hide-overlay
            transition="dialog-bottom-transition"
          >
            <template #activator="{ on, attrs }">
              <v-badge
                :color="$root.lightHeaderText ? 'white' : 'grey'"
                offset-x="18px"
                offset-y="22px"
                class="tasks-badge"
                :value="!!$root.tasksLeft"
                :content="$root.tasksLeft"
              >
                <v-btn
                  icon
                  text
                  v-bind="attrs"
                  width="42"
                  height="42"
                  class="my-1"
                  :dark="$root.lightHeaderText"
                  v-on="on"
                >
                  <v-icon>
                    fas fa-tasks
                  </v-icon>
                </v-btn>
              </v-badge>
            </template>
            <v-card>
              <v-toolbar
                :dark="$root.lightHeaderText"
                color="var(--highlightColor)"
              >
                <v-btn
                  icon
                  :dark="$root.lightHeaderText"
                  @click="$root.showTasks = false"
                >
                  <v-icon>mdi-close</v-icon>
                </v-btn>
                <v-toolbar-title>Tasks</v-toolbar-title>
              </v-toolbar>
              <tasks style="display: flex; flex-direction: column; overflow: hidden; border-radius: 8px !important;" />
            </v-card>
          </v-dialog>

          <v-btn
            icon
            text
            width="42"
            height="42"
            class="my-1 mr-1"
            :dark="$root.lightHeaderText"
            @click="$root.state.settingsOpen = !$root.state.settingsOpen"
          >
            <v-avatar
              v-if="$root.userProfile && $root.userProfile.profilePic"
              size="24"
            >
              <v-img
                :src="$root.userProfile.profilePic"
                :alt="$root.userProfile.name"
              />
            </v-avatar>
            <v-icon v-else>
              fas fa-user-circle
            </v-icon>
          </v-btn>
        </div>
      </div>
    </div>

    <div
      v-else
      class="header stock mega-menu"
      :style="{
        boxShadow: ($router.currentRoute.name === 'TaskPage' || $router.currentRoute.name === 'TaskPageHome' || $router.currentRoute.name === 'MaterialPage') ? 'rgba(0, 0, 0, ' + Math.min(0.315, ($root.pageScroll / 500)) + ') 0 0 20px 0px' : 'rgba(0, 0, 0, 0.315) 0 0 20px 0px'
      }"
    >
      <v-btn
        v-if="$vuetify.breakpoint.mobile"
        icon
        text
        width="42"
        height="42"
        class="my-1 ml-1"
        @click="showMegaMenu = !showMegaMenu; megaMenuTopic = megaMenuTopic || 'materials'"
      >
        <v-icon
          style="transition: 0.3s"
          aria-label="Menu"
          :style="{ transform: showMegaMenu ? 'rotate(90deg)' : '' }"
        >
          fas fa-bars
        </v-icon>
      </v-btn>
      <div class="header-wrapper">
        <a
          v-if="$root.configPublic.redirectLogoClick && $root.configPublic.redirectLogoClickURL"
          :href="$root.configPublic.redirectLogoClickURL"
          style="height: 50px; text-decoration: none;"
          :class="{ 'ml-2': $vuetify.breakpoint.mobile }"
        >
          <img
            v-if="$vuetify.breakpoint.mobile && $root.theme.logoMobile"
            style="object-fit: contain; height: 50px; transition: 0.2s; cursor: pointer; border-top-left-radius: min(16px, env(safe-area-inset-top));"
            :alt="brandName"
            :src="$root.shrunkImage($root.theme.logoMobile, null, 50)"
            :aria-label="brandName"
          >
          <img
            v-else-if="$root.theme.logo"
            style="object-fit: contain; height: 50px; transition: 0.2s; cursor: pointer; border-top-left-radius: min(16px, env(safe-area-inset-top));"
            :alt="brandName"
            :src="$root.shrunkImage($root.theme.logo, null, 50)"
            :aria-label="brandName"
          >
          <v-btn
            v-else
            text
            icon
            width="42"
            height="42"
            class="my-1 ml-2"
            style="text-decoration: none;"
            :dark="$root.lightHeaderText"
          >
            <v-icon>
              fas fa-home
            </v-icon>
          </v-btn>
        </a>
        <router-link
          v-else
          to="/"
          style="height: 50px; text-decoration: none;"
          :class="{ 'ml-2': $vuetify.breakpoint.mobile }"
        >
          <img
            v-if="$vuetify.breakpoint.mobile && $root.theme.logoMobile"
            style="object-fit: contain; height: 50px; transition: 0.2s; cursor: pointer; border-top-left-radius: min(16px, env(safe-area-inset-top));"
            :alt="brandName"
            :src="$root.shrunkImage($root.theme.logoMobile, null, 50)"
            :aria-label="brandName"
          >
          <img
            v-else-if="$root.theme.logo"
            style="object-fit: contain; height: 50px; transition: 0.2s; cursor: pointer; border-top-left-radius: min(16px, env(safe-area-inset-top));"
            :alt="brandName"
            :src="$root.shrunkImage($root.theme.logo, null, 50)"
            :aria-label="brandName"
          >
          <v-btn
            v-else
            text
            icon
            width="42"
            height="42"
            class="my-1 ml-2"
            style="text-decoration: none;"
            :dark="$root.lightHeaderText"
          >
            <v-icon>
              fas fa-home
            </v-icon>
          </v-btn>
        </router-link>
        <div
          class="header-right"
          style="white-space: nowrap;"
        >
          <div v-if="!$vuetify.breakpoint.mobile">
            <v-btn
              v-if="$root.enterprise.enableMaterialTasks && $root.enterprise.materialTasksInHeader"
              text
              height="34px"
              rounded
              class="ma-0 mt-2"
              to="/material-guide"
              @mouseenter="showMegaMenu = true; megaMenuTopic = 'materials';"
            >
              <span class="mx-n4">Materials</span>
            </v-btn>
            <v-btn
              v-for="list in megaMenuLists"
              :key="list.uuid"
              text
              height="34px"
              rounded
              class="ma-0 mt-2"
              :to="list.megaMenuLink"
              @click="showMegaMenu = (list.megaMenuLink ? false : showMegaMenu)"
              @mouseenter="showMegaMenu = true; megaMenuTopic = list.uuid;"
            >
              <span class="mx-n4">{{ list.name }}</span>
            </v-btn>
            <v-btn
              v-if="$root.enterprise.enableStore"
              text
              height="34px"
              rounded
              class="ma-0 mt-2"
              to="/products"
              @mouseenter="showMegaMenu = true; megaMenuTopic = 'store';"
            >
              <span class="mx-n4">Filament Store</span>
            </v-btn>
          </div>
          <v-spacer />
          <v-menu
            offset-y
            :disabled="!$root.currentUser"
            open-on-hover
          >
            <template #activator="{ on, attrs }">
              <v-btn
                v-if="$vuetify.breakpoint.mobile"
                text
                height="42"
                width="42"
                class="mt-1"
                :to="$root.currentUser ? '/projects' : { name: 'Login', query: { redirect: $route.path } }"
                icon
                :aria-label="$root.currentUser ? 'Account' : 'Login'"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>
                  fas fa-user
                </v-icon>
              </v-btn>
              <v-btn
                v-else
                text
                height="34"
                v-bind="attrs"
                rounded
                class="mx-2 mb-0 mt-2 px-0"
                :aria-label="$root.currentUser ? 'Account' : 'Login'"
                :to="$root.currentUser ? '/projects' : { name: 'Login', query: { redirect: $route.path } }"
                v-on="on"
              >
                <div class="d-flex mx-n4">
                  <v-icon
                    x-small
                    left
                  >
                    fas fa-user
                  </v-icon>
                  {{ $root.currentUser ? ($root.userProfile && $root.userProfile.fullName ? $root.userProfile.fullName.split(' ')[0] : 'Account') : 'Login' }}
                </div>
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                color="var(--highlightColor)"
                to="/projects"
              >
                <v-list-item-icon>
                  <v-icon>
                    fas fa-shopping-cart
                  </v-icon>
                </v-list-item-icon>
                <v-list-item-title>My Orders</v-list-item-title>
              </v-list-item>
              <v-list-item
                color="var(--highlightColor)"
                to="/collection/orders"
              >
                <v-list-item-icon>
                  <v-icon>
                    fas fa-cube
                  </v-icon>
                </v-list-item-icon>
                <v-list-item-title>Purchased Models</v-list-item-title>
              </v-list-item>
              <v-list-item
                color="var(--highlightColor)"
                to="/collection/home"
              >
                <v-list-item-icon>
                  <v-icon>
                    fas fa-cubes
                  </v-icon>
                </v-list-item-icon>
                <v-list-item-title>Model Library</v-list-item-title>
              </v-list-item>
              <v-list-item
                color="var(--highlightColor)"
                to="/account"
              >
                <v-list-item-icon>
                  <v-icon>
                    fas fa-user-gear
                  </v-icon>
                </v-list-item-icon>
                <v-list-item-title>Manage Account</v-list-item-title>
              </v-list-item>

              <v-list-item
                color="var(--highlightColor)"
                @click="$root.logout"
              >
                <v-list-item-icon>
                  <v-icon>
                    fas fa-sign-out-alt
                  </v-icon>
                </v-list-item-icon>
                <v-list-item-title>Log Out</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <v-menu
            v-if="$root.currentUser && !$vuetify.breakpoint.mobile"
            v-model="$root.showNotifications"
            :close-on-content-click="false"
            :close-on-click="!showPopTask"
            content-class="elevation-0 pa-1"
            :nudge-width="200"
            offset-y
            bottom
          >
            <template #activator="{ on, attrs }">
              <v-badge
                color="red"
                offset-x="22px"
                offset-y="24px"
                :content="notificationBadgeContent"
                :value="!!notificationBadgeContent"
              >
                <v-btn
                  icon
                  text
                  v-bind="attrs"
                  width="42"
                  height="42"
                  class="my-1"
                  :dark="$root.lightHeaderText"
                  v-on="on"
                >
                  <v-icon>
                    fas fa-bell
                  </v-icon>
                </v-btn>
              </v-badge>
            </template>

            <v-card
              width="480px"
              class="ma-0"
            >
              <notifications style="max-height: 80vh; display: flex; flex-direction: column; overflow: hidden;" />
            </v-card>
          </v-menu>
          <v-dialog
            v-if="$root.currentUser && $vuetify.breakpoint.mobile"
            v-model="$root.showNotifications"
            fullscreen
            hide-overlay
            transition="dialog-bottom-transition"
          >
            <template #activator="{ on, attrs }">
              <v-badge
                color="red"
                offset-x="20px"
                offset-y="24px"
                :content="notificationBadgeContent"
                :value="!!notificationBadgeContent"
              >
                <v-btn
                  icon
                  text
                  v-bind="attrs"
                  width="42"
                  height="42"
                  class="my-1"
                  :dark="$root.lightHeaderText"
                  v-on="on"
                >
                  <v-icon>
                    fas fa-bell
                  </v-icon>
                </v-btn>
              </v-badge>
            </template>
            <v-card>
              <v-toolbar
                :dark="$root.lightHeaderText"
                color="var(--highlightColor)"
              >
                <v-btn
                  icon
                  :dark="$root.lightHeaderText"
                  @click="$root.showNotifications = false"
                >
                  <v-icon>mdi-close</v-icon>
                </v-btn>
                <v-toolbar-title>Notifications</v-toolbar-title>
              </v-toolbar>
              <notifications style="display: flex; flex-direction: column; overflow: hidden;" />
            </v-card>
          </v-dialog>
          <div
            v-if="formfactoriesSite && 'lol' === 'thisFeatureIsntReady'"
            class="ma-2 d-flex"
            style="gap: 8px;"
          >
            <v-btn
              outlined
              height="32px"
              color="var(--highlightColor)"
              dark
              rounded
              to="/demo"
            >
              Get a Demo
              <v-icon
                color="var(--highlightColor)"
                small
                right
              >
                fas fa-bolt
              </v-icon>
            </v-btn>
            <v-btn
              v-if="$root.staffEnterprises.length === 1"
              height="32px"
              dark
              rounded
              class="white--text"
              color="var(--highlightColor)"
              :href="'https://' + $root.staffEnterprises[0].url"
            >
              Dashboard
              <v-icon
                small
                right
                color="white"
              >
                fas fa-chevron-right
              </v-icon>
            </v-btn>
            <v-menu
              v-if="$root.staffEnterprises.length > 1"
              offset-y
            >
              <template #activator="{ on, attrs }">
                <v-btn
                  height="32px"
                  dark
                  rounded
                  class="white--text"
                  color="var(--highlightColor)"
                  v-bind="attrs"
                  v-on="on"
                >
                  Dashboard
                  <v-icon
                    small
                    right
                    color="white"
                  >
                    fas fa-chevron-right
                  </v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item
                  v-for="enterprise in $root.staffEnterprises"
                  :key="enterprise.id"
                  :href="'https://' + enterprise.url"
                >
                  <v-list-item-title>{{ enterprise.text }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
          <div v-else-if="$route.path !== '/submit'">
            <v-btn
              v-if="$vuetify.breakpoint.xs"
              class="my-1 mr-1 ml-1"
              :to="$router.currentRoute.name === 'ProjectPage' ? '/submit' : ''"
              height="42"
              width="42"
              :dark="$root.lightHeaderText"
              fab
              color="var(--highlightColor)"
              style="color: white; margin-top: 8px;"
              @click="$router.currentRoute.name !== 'ProjectPage' ? $root.showProjectPreview = true : false"
            >
              <v-icon>fas fa-upload</v-icon>
            </v-btn>
            <v-btn
              v-else-if="showSubmitFilesButton"
              class="mx-2 mb-0"
              :to="$router.currentRoute.name === 'ProjectPage' ? '/submit' : ''"
              rounded
              height="32px"
              :dark="$root.lightHeaderText"
              color="var(--highlightColor)"
              style="color: white; margin-top: 8px;"
              @click="$router.currentRoute.name !== 'ProjectPage' ? $root.showProjectPreview = true : false"
            >
              <span>{{ $vuetify.breakpoint.mobile ? 'Submit' : 'Submit Files' }}<em class="fas fa-upload ml-2" /></span>
            </v-btn>
            <v-menu
              v-if="$root.enableProjectPricing && !$vuetify.breakpoint.mobile"
              v-model="$root.cartOpen"
              offset-y
              max-width="470"
              min-width="470"
              transition="slide-y-transition"
              max-height="min(80vh, 720px)"
              left
              class="cart-menu"
              content-class="cart-menu-content mt-2"
              :close-on-content-click="false"
            >
              <template #activator="{ on, attrs }">
                <v-badge
                  color="var(--highlightColor)"
                  :value="!!$root.projectPreviewNumItems"
                  :content="$root.projectPreviewNumItems"
                  offset-x="32"
                  offset-y="24"
                >
                  <v-btn
                    class="mt-2 mr-2 ml-1"
                    height="34"
                    fab
                    text
                    rounded
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon>fas fa-shopping-cart</v-icon>
                  </v-btn>
                </v-badge>
              </template>
              <div
                class="cart-wrapper-menu"
                style="overflow: auto; height: 100%; background-color: white;"
              >
                <content-background :theme="$root.theme" />
                <project-page
                  v-if="$root.projectPreview"
                  class="cart-project-menu"
                  :uuid="$root.projectPreview"
                  disable-title
                  simplified
                  @num-items="$root.projectPreviewNumItems = $event"
                />
              </div>
            </v-menu>
            <v-badge
              v-if="$root.enableProjectPricing && $vuetify.breakpoint.xs"
              color="var(--highlightColor)"
              :value="!!$root.projectPreviewNumItems"
              :content="$root.projectPreviewNumItems"
              offset-x="24"
              offset-y="24"
            >
              <v-btn
                class="my-1 mr-2 ml-0"
                height="42"
                width="42"
                text
                fab
                @click="$root.showProjectPreview = !$root.showProjectPreview"
              >
                <v-icon>fas fa-shopping-cart</v-icon>
              </v-btn>
            </v-badge>
          </div>
        </div>
      </div>
    </div>

    <v-card
      v-if="$root.currentUser && $root.userProfile"
      class="settings-pane my-0 rounded-0"
      :dark="$root.lightHeaderText"
      style="overflow: auto"
      :class="{ active: $root.state.settingsOpen }"
    >
      <div
        v-if="$root.userProfile"
        class="userProfile mt-4 mb-8"
      >
        <div
          class="profilePic"
          onclick="document.getElementById('profilePicUploader').click()"
        >
          <div
            v-show="$root.state.uploadingProfilePic"
            id="loading-icon"
          >
            <div class="profile-spinner">
              <div class="double-bounce1" />
              <div class="double-bounce2" />
            </div>
          </div>
          <v-img
            v-show="!$root.state.uploadingProfilePic"
            id="profilePicImg"
            alt="Profile Picture"
            :src="$root.userProfile.profilePic ? $root.userProfile.profilePic : $root.shrunkImage('https://firebasestorage.googleapis.com/v0/b/formfactories-incept3d.appspot.com/o/media%2F85438e81-5bf0-4abd-8731-515e0c0b9588%2Fflexirex.jpg?alt=media&token=23b90922-0aa7-4bec-9885-7f42a765508a', 200)"
          />
          <div class="editIcon">
            <em class="fas fa-edit" />
          </div>
          <input
            id="profilePicUploader"
            type="file"
            class="profilePicUploader"
            @change="pickNewProfilePic($event); $event.target.value = '';"
          >
        </div>
        <p class="userEmail">
          {{ $root.currentUser.email ? $root.currentUser.email : '' }}
        </p>
        <div
          class="d-flex justify-center"
          style="gap: 8px;"
        >
          <v-btn
            to="/account"
            small
            color="var(--highlightColor)"
            dark
            @click="$root.state.settingsOpen = false"
          >
            <v-icon
              left
              small
            >
              fas fa-user-cog
            </v-icon> Account
          </v-btn>
          <v-btn
            to="/collection/home"
            small
            color="var(--highlightColor)"
            dark
            @click="$root.state.settingsOpen = false"
          >
            <v-icon
              left
              small
            >
              fas fa-cubes
            </v-icon> Part Library
          </v-btn>
        </div>
        <v-text-field
          v-model="$root.userProfile.fullName"
          label="Full Name"
          class="mt-6"
          dense
        />
        <v-text-field
          v-if="$root.isStaff || $root.isAlumni"
          v-model="$root.userProfile.displayName"
          label="Display Name"
          dense
        />
        <v-text-field
          v-if="!($root.isStaff || $root.isAlumni)"
          v-model="$root.userProfile.company"
          label="Company"
          dense
        />
        <v-text-field
          v-model="$root.userProfile.phone"
          label="Phone Number"
          dense
        />
        <v-text-field
          v-if="$root.isStaff || $root.isAlumni"
          v-model="$root.userProfile.calendarUrl"
          label="Calendar Link"
          dense
        />
        <v-btn
          block
          dark
          class="mt-2"
          color="var(--highlightColor)"
          @click="$root.logout"
        >
          Log Out
        </v-btn>
        <v-btn
          block
          text
          class="mt-2"
          small
          color="var(--highlightColor)"
          @click="deleteAccount"
        >
          Delete Account
        </v-btn>
      </div>

      <v-spacer />

      <div
        v-if="$root.isStaff || $root.isAlumni"
        class="setting"
      >
        <div class="d-flex">
          <v-spacer />
          <v-icon :disabled="$root.darkModePreference === true">
            fas fa-sun
          </v-icon>
          <v-switch
            v-model="$root.darkModePreference"
            :dark="$root.lightHeaderText"
            color="var(--highlightColor)"
            class="ml-4 mr-2"
          />
          <v-icon :disabled="$root.darkModePreference !== true">
            fas fa-moon
          </v-icon>
          <v-spacer />
        </div>
        <v-select
          v-if="$root.staffEnterprises.length > 1"
          v-model="$root.enterpriseOverride"
          label="Enterprise"
          outlined
          hide-details
          class="mb-4"
          dense
          prepend-icon="fas fa-building"
          :items="$root.staffEnterpriseOptions"
        />
        <div
          class="theme-switch-wrapper"
          style="position: relative"
        >
          <label
            v-if="$root.publicBrands.length"
            for="theme-switcher"
          >Theme</label>
          <h6
            v-if="$root.holiday || ($root.userProfile && $root.userProfile.prefs_disableHolidayExpiration && $root.userProfile.prefs_disableHolidayExpiration > Date.now())"
            style="position: absolute; top: 24px; left: 0; cursor: pointer;"
            @click="$set($root.userProfile, 'prefs_disableHolidayExpiration', $root.holiday ? Date.now() + (24 * 60 * 60 * 1000) : 0)"
          >
            {{ $root.holiday ? 'Disable' : 'Enable' }} This Holiday
          </h6>
          <select
            v-if="$root.publicBrands.length"
            id="theme-switcher"
            v-model="$root.userProfile.prefs_brand"
            class="theme-switch-select"
            label="theme"
          >
            <option value="">
              Default
            </option>
            <option
              v-for="brand in $root.publicBrands"
              :key="brand.id"
              :value="brand.id"
            >
              {{ brand.name }}
            </option>
          </select>
        </div>
      </div>

      <div
        v-if="$root.userProfile && $root.userProfile.prefs_brand === 'debug'"
        class="setting"
      >
        <v-checkbox
          v-model="$root.userProfile.prefs_headerTextColor"
          label="Dark Text"
          dense
        />
        <v-text-field
          v-model="$root.userProfile.prefs_headerOverride"
          label="Header Color Override"
          dense
        >
          <template #append>
            <v-menu
              top
              offset-y
              :close-on-content-click="false"
            >
              <template #activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                >
                  fas fa-swatchbook
                </v-icon>
              </template>
              <v-color-picker
                v-model="$root.userProfile.prefs_headerOverride"
                show-swatches
                mode="hexa"
              />
            </v-menu>
          </template>
        </v-text-field>
        <v-text-field
          v-model="$root.userProfile.prefs_accentOverride"
          label="Accent Color Override"
          dense
        >
          <template #append>
            <v-menu
              top
              offset-y
              :close-on-content-click="false"
            >
              <template #activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                >
                  fas fa-swatchbook
                </v-icon>
              </template>
              <v-color-picker
                v-model="$root.userProfile.prefs_accentOverride"
                show-swatches
                mode="hexa"
              />
            </v-menu>
          </template>
        </v-text-field>
      </div>

      <div
        v-if="$root.isStaff || $root.isAlumni"
        class="setting"
      >
        <div class="theme-switch-wrapper">
          <label>Swift Mode <info-tip label="Disable larger models from initially loading in 3D view" /></label>
          <label
            class="theme-switch"
            for="checkbox_la"
          >
            <input
              id="checkbox_la"
              v-model="$root.userProfile.prefs_swiftMode"
              type="checkbox"
            >
            <div class="slider round" />
          </label>
        </div>
      </div>

      <div
        v-if="($root.isStaff || $root.isAlumni) && $root.configPublic.enableStaffBetaOptIn"
        class="setting"
      >
        <div class="theme-switch-wrapper">
          <label>Beta Features</label>
          <label
            class="theme-switch"
            for="checkbox_beta"
          >
            <input
              v-if="$root.userProfile"
              id="checkbox_beta"
              v-model="$root.userProfile.prefs_beta"
              type="checkbox"
            >
            <div class="slider round" />
          </label>
        </div>
      </div>

      <v-switch
        v-if="$root.isAdmin"
        v-model="$root.showMemory"
        label="Debug Mode"
        :dark="$root.lightHeaderText"
        hide-details
        color="var(--highlightColor)"
        class="ml-4 mr-2 mt-0"
      />

      <!-- <div
        v-if="$root.isStaff || $root.isAlumni"
        class="setting"
      >
        <div class="theme-switch-wrapper">
          <label>Show Debug</label>
          <label
            class="theme-switch"
            for="checkbox_debug"
          >
            <input
              id="checkbox_debug"
              v-model="$root.userProfile.prefs_debug"
              type="checkbox"
            >
            <div class="slider round" />
          </label>
        </div>
      </div> -->

      <div
        v-if="$root.isStaff || $root.isAlumni"
        class="d-flex"
        style="padding: 16px 6px 6px 6px; margin-bottom: max(0px, env(safe-area-inset-bottom)); width: 100%;"
      >
        <v-btn
          v-if="$root.bugReportingChat"
          text
          small
          @click="$root.openChat($root.bugReportingChat); $root.state.settingsOpen = false;"
        >
          <v-icon
            x-small
            left
          >
            fas fa-bug
          </v-icon>Report a bug
        </v-btn>

        <v-spacer />

        <div style="cursor: pointer; text-align: right;">
          <v-dialog width="400">
            <template #activator="{ on, attrs }">
              <v-btn
                text
                small
                v-bind="attrs"
                v-on="on"
              >
                <v-icon
                  x-small
                  left
                >
                  fas fa-history
                </v-icon>Changelog
              </v-btn>
            </template>
            <changelog />
          </v-dialog>
        </div>
      </div>
    </v-card>

    <div
      class="click-off-pane"
      :class="{ active: $root.state.settingsOpen }"
      @click="$root.state.settingsOpen = false"
    />

    <v-dialog v-model="$root.printerSnapshotBrowser">
      <machine-snapshot-viewer />
    </v-dialog>

    <v-dialog v-model="$root.printerSnapshotFullscreen">
      <machine-snapshot-fullscreen-viewer />
    </v-dialog>

    <v-dialog
      v-if="popTask"
      :key="popTask.uuid"
      v-model="showPopTask"
      width="800"
    >
      <v-card>
        <task-content
          style="overflow: hidden;"
          :uuid="popTask.uuid"
        />
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  import helpers from '@/mixins/helpers'
  import QrScanner from 'qr-scanner'
  import { BarcodeScanner } from '@capacitor-community/barcode-scanner'
  import { ref, getDownloadURL, uploadBytesResumable } from 'firebase/storage'
  import { onSnapshot, collection, query, where, orderBy } from '@firebase/firestore'

  export default {
    name: 'NavigationBar',
    components: {
      ProjectCard: () => import('@/components/project/ProjectCard'),
      UserCard: () => import('@/components/extras/UserCard'),
      OrganizationCard: () => import('@/components/extras/OrganizationCard'),
      printerMenu: () => import('@/components/extras/PrinterMenu'),
      notifications: () => import('@/components/extras/Notifications'),
      tasks: () => import('@/components/extras/Tasks'),
      taskContent: () => import('@/components/extras/TaskContent'),
      changelog: () => import('@/components/extras/Changelog'),
      Machines: () => import('@/components/staff/Machines'),
      machineSnapshotViewer: () => import('@/components/extras/MachineSnapshotViewer'),
      machineSnapshotFullscreenViewer: () => import('@/components/extras/MachineSnapshotFullscreenViewer'),
      ProjectPage: () => import('@/components/project/ProjectPage'),
      ContentBackground: () => import('@/components/extras/ContentBackground.vue'),
      MaterialRadarChart: () => import('@/components/extras/MaterialRadarChart.vue'),
      AppLogo: () => import('@/components/AppLogo.vue'),
      InfoTip: () => import('@/components/extras/InfoTip'),
      Task: () => import('@/components/extras/Task'),
    },
    mixins: [helpers],
    data () {
      return {
        creatingProject: false,
        storeStyle: 2,
        materialHover: null,
        productHover: null,
        imageHover: {},
        showMegaMenu: false,
        megaMenuTopic: 'materials',
        searchQuery: '',
        searching: false,
        searchActive: false,
        projectSearchResults: [],
        userSearchResults: [],
        organizationSearchResults: [],
        chatSearchResults: [],
        searchCategory: 'projects',
        showResults: false,
        projectHighlightedResult: null,
        userHighlightedResult: null,
        organizationHighlightedResult: null,
        chatHighlightedResult: null,
        rtxRefresh: false,
        qrScanner: null,
        qrScannerOpen: false,
        qrScannerError: null,
        qrScannerLoading: false,
        printersOpen: false,
        blink: false,
        hideOperationalPrinters: false,
        popTask: null,
        showPopTask: false,
        popTaskElementHighlight: null,
        popTaskShowChatOverride: null,
        productCollectionHighlights: [],
        products: {},
        categoriesListener: null,
        categories: [],
      }
    },
    computed: {
      notificationBadgeContent: function () {
        let textSegs = []
        if (this.$root.unreadNotifications) textSegs.push(String(this.$root.unreadNotifications))
        const otherEnterpriseNotificationCounts = Object.values(this.$root.staffEnterpriseUnreadNotificationCount).reduce((a, b) => a + b, 0)
        if (otherEnterpriseNotificationCounts) textSegs.push(`(+${otherEnterpriseNotificationCounts})`)
        return textSegs.join(' ')
      },
      showSubmitFilesButton: function () {
        return (this.$root.primaryProjectStatus !== 5 || !this.$route.path.startsWith('/projects/')) && this.$route?.name !== 'EnterpriseSignup'
      },
      formfactoriesSite: function () {
        return this.$root.enterpriseID === 'TgNi8mpKJTnZ3Lt4Dzfp'
      },
      firstProduct: function () {
        const firstCollection = this.productCollectionHighlights.find(collection => collection.products.filter(p => this.products[p] && Object.values(this.products[p].variantData).filter(v => v.stock > 0).length).length)
        if (firstCollection) {
          const firstProduct = firstCollection.products.find(p => this.products[p] && Object.values(this.products[p].variantData).filter(v => v.stock > 0).length)
          if (firstProduct) return firstProduct
          else return null
        } else return null
      },
      brandName: function () {
        return this.$root.brand.name
      },
      navigationItems: function () {
        const lists = Array.from(new Set(this.$root.publicTasks.map(x => x.list)))
          .sort((a, b) => {
            const aLocation = this.$root.taskLists.map(x => x.uuid).indexOf(a)
            const bLocation = this.$root.taskLists.map(x => x.uuid).indexOf(b)
            return aLocation - bLocation
          })

        const subcategories = Array.from(new Set(lists.flatMap(id => (this.$root.taskLists.find(x => x.uuid === id) || {}).megaMenuSubcategories || [])))
        return lists.filter(x => !subcategories.includes(x) && ((this.$root.taskLists.find(y => y.uuid === x) || {}).megaMenu || x === 'd4d42e4d-1fa0-4252-af8e-8c84b7bc4ce7'))
      },
      megaMenuLists: function () {
        return (this.$root.enterprise.taskLists || []).filter(x => x.megaMenu)
      },
      materials: function () {
        return (((this.$root.catalog || []).find(x => x.name === 'Material') || {}).children || [])
      },
      headerTextOverride: function () {
        return this.$root.userProfile ? this.$root.userProfile.prefs_headerTextColor : false
      },
      accentOverride: function () {
        return this.$root.userProfile ? this.$root.userProfile.prefs_accentOverride : ''
      },
      headerOverride: function (val) {
        return this.$root.userProfile ? this.$root.userProfile.prefs_headerOverride : ''
      },
    },
    watch: {
      firstProduct: function (val) {
        if (val && !this.productHover) this.productHover = this.products[val]
      },
      $route: function (newVal, oldVal) {
        if (oldVal.path === '/submit' && newVal.path.startsWith('/projects/')) return
        this.showMegaMenu = false
        this.$root.showTaskReader = false
        this.$root.showProjectPreview = false
        this.$root.cartOpen = false
      },
      materials: {
        immediate: true,
        handler: function (val) {
          if (!this.materialHover && val[0]) this.materialHover = val[0]
        },
      },
      headerTextOverride: {
        handler: function (val) {
          if (this.$root.userProfile && this.$root.userProfile.prefs_brand === 'debug') document.documentElement.style.setProperty('--headerTextColor', val ? '#292929' : '')
        },
        immediate: true,
      },
      accentOverride: {
        handler: function (val) {
          if (this.$root.userProfile && this.$root.userProfile.prefs_brand === 'debug') document.documentElement.style.setProperty('--highlightColor', val)
        },
        immediate: true,
      },
      headerOverride: {
        handler: function (val) {
          if (this.$root.userProfile && this.$root.userProfile.prefs_brand === 'debug') document.documentElement.style.setProperty('--headerColor', val)
        },
        immediate: true,
      },
      searchQuery: {
        handler (val) {
          if (val.length > 0 && this.$root.searchProjectIndex) {
            this.searching = true
            this.$root.algoliaClient.multipleQueries([
              { indexName: 'projects', query: val },
              { indexName: 'users', query: val },
              { indexName: 'organizations', query: val },
              { indexName: 'chats', query: val, params: { filters: `taskUsers:${this.$root.currentUser.uid} AND active:true AND type:task` } }
            ])
              .then(resp => {
                if (this.searchQuery === val) {
                  this.searching = false
                  this.projectSearchResults = resp.results[0].hits
                  this.projectHighlightedResult = this.projectSearchResults[0]
                  this.userSearchResults = resp.results[1].hits
                  this.userHighlightedResult = this.userSearchResults[0]
                  this.organizationSearchResults = resp.results[2].hits
                  this.organizationHighlightedResult = this.organizationSearchResults[0]
                  this.chatSearchResults = resp.results[3].hits
                  this.chatHighlightedResult = this.chatSearchResults[0]
                }
              })
          } else {
            this.searching = false
            this.projectSearchResults = []
            this.projectUserResults = []
            this.organizationSearchResults = []
            this.chatSearchResults = []
          }
        },
      },
      qrScannerOpen: async function (newVal, oldVal) {
        this.qrScannerError = null
        if (newVal) {
          try {
            await BarcodeScanner.checkPermission({ force: true })
          } catch (x) {}
        }

        const app = this
        this.$nextTick(function () {
          if (newVal) {
            // Create QR scanner
            if (!app.qrScanner || !document.body.contains(app.qrScanner.$video)) {
              app.qrScanner = new QrScanner(
                app.$refs.scanner,
                code => {
                  let result = code.data
                  const url = new URL(result)
                  if (url && url.pathname) {
                    app.$router.push(url.pathname).catch(x => x)
                    app.qrScannerOpen = false
                  }
                },
                {
                  returnDetailedScanResult: true,
                },
              )
            }

            // Start scanning
            app.qrScannerLoading = true
            app.qrScanner.start()
              .then(() => {
                this.qrScannerLoading = false
                this.qrScannerError = null
              })
              .catch(err => {
                this.qrScannerLoading = false
                this.qrScannerError = err
              })
          } else if (app.qrScanner) app.qrScanner.stop()
        })
        if (newVal && !oldVal) window.history.pushState({}, null, '/scanner')
        else if (oldVal && !newVal) window.history.back()
      },
    },
    created: function () {
      // Download highlighted product collections
      onSnapshot(
        query(
          collection(this.$root.db, 'enterprises', this.$root.enterpriseID, 'productCollections'),
          where('starred', '==', true),
        ),
        snap => {
          this.productCollectionHighlights = snap.docs.map(doc => Object.assign(doc.data(), { id: doc.id }))
            .sort((a, b) => a.sort - b.sort)
        },
        err => {
          console.log('Error listening to product collections', err)
        },
      )

      // Download products
      onSnapshot(
        query(
          collection(this.$root.db, 'enterprises', this.$root.enterpriseID, 'products'),
          where('active', '==', true),
        ),
        snap => {
          this.products = {}
          snap.docs.forEach(doc => {
            this.$set(this.products, doc.id, doc.data())
          })
        },
        err => {
          console.log('Error listening to products', err)
        },
      )

      // Download product categories
      this.categoriesListener = onSnapshot(
        query(
          collection(this.$root.db, 'enterprises', this.$root.enterpriseID, 'productCategories'),
          orderBy('navigationSort'),
        ),
        snap => {
          this.categories = snap.docs.map(d => Object.assign(d.data(), { id: d.id }))
        },
        err => {
          console.log('Error listening to categories', err)
        },
      )

      this.$root.$on('key-up-code', code => {
        if (this.showResults) {
          if (code === 'ArrowDown' || code === 'ArrowUp') {
            let results, highlightedResult
            const increment = code === 'ArrowDown' ? 1 : -1
            if (this.searchCategory === 'projects') {
              results = this.projectSearchResults
              highlightedResult = this.projectHighlightedResult
            } else if (this.searchCategory === 'users') {
              results = this.userSearchResults
              highlightedResult = this.userHighlightedResult
            } else if (this.searchCategory === 'organizations') {
              results = this.organizationSearchResults
              highlightedResult = this.organizationHighlightedResult
            } else if (this.searchCategory === 'chats') {
              results = this.chatSearchResults
              highlightedResult = this.chatHighlightedResult
            }
            if (results.length) {
              const index = results.indexOf(highlightedResult)
              if (index + increment >= 0 && index + increment < results.length) {
                if (this.searchCategory === 'projects') {
                  this.projectHighlightedResult = results[index + increment]
                } else if (this.searchCategory === 'users') {
                  this.userHighlightedResult = results[index + increment]
                } else if (this.searchCategory === 'organizations') {
                  this.organizationHighlightedResult = results[index + increment]
                } else if (this.searchCategory === 'chats') {
                  this.chatHighlightedResult = results[index + increment]
                }
              }
            }
          } else if (code === 'Enter') {
            if (this.$refs.highlightedResult?.[0]?.$el) this.$refs.highlightedResult[0].$el.click()
            document.getElementById('search-query').blur()
          }
        }
      })
    },
    mounted: function () {
      const app = this
      window.addEventListener('click', function (e) {
        if (document.getElementById('search-wrapper') && !document.getElementById('search-wrapper').contains(e.target)) {
          app.showResults = false
        }
      })
      window.addEventListener('keypress', function (e) {
        if (document.getElementsByTagName('BODY')[0] === document.activeElement || document.activeElement.tagName === 'BUTTON') {
          switch (String.fromCharCode(e.keyCode)) {
            case 's':
              e.preventDefault()
              document.getElementById('search-query').focus()
              break
            default:
          }
        }
      })

      this.$root.navigation = this
      if (window.openScanner) {
        window.openScanner = false
        this.$nextTick(() => (this.qrScannerOpen = true))
      }
    },
    methods: {
      createProject: function () {
        this.creatingProject = true
        this.$root.callFunction('createProject', { enterprise: this.$root.enterpriseID })
          .then(resp => {
            this.$router.push(`/projects/${resp.data}`)
            this.creatingProject = false
          })
          .catch(error => {
            alert(`Error creating new project:\n${error.message}\n(${error.code})`)
            this.creatingProject = false
          })
      },
      activeCategoryProducts: function (catID) {
        return Object.keys(this.products).filter(p => {
          const product = this.products[p]
          if (product.category !== catID) return false
          return Object.values(product.variantData).filter(v => v.active && v.stock > 0).length > 0
        })
      },
      deleteAccount: function () {
        if (confirm('Are you sure you want to delete your account? This cannot be undone')) {
          this.$root.deleteAccount()
        }
      },
      listTasks: function (list) {
        return this.$root.publicTasks
          .filter(x => x.list === list)
          .sort((a, b) => {
            if ((a.readerSortOrder || Infinity) === (b.readerSortOrder || Infinity)) return a.created - b.created
            else return (b.readerSortOrder || Infinity) - (a.readerSortOrder || Infinity)
          })
      },
      listContents: function (list) {
        // Generate all tasks and lists
        const tasks = this.listTasks(list).map(task => ({
          order: +task.readerSortOrder || Infinity,
          type: 'task',
          task: task,
          uuid: task.uuid,
        }))
        const listData = (this.$root.taskLists.find(x => x.uuid === list) || {})
        const lists = (listData.megaMenuSubcategories || []).map(cat => ({
          order: +listData.megaMenuSubcategorySortOrders[cat] || 0,
          type: 'subcategory',
          uuid: `${list}-${cat}`,
          name: (this.$root.taskLists.find(x => x.uuid === cat) || {}).name,
          tasks: this.listTasks(cat).sort((a, b) => (+a.readerSortOrder || Infinity) - (+b.readerSortOrder || Infinity)),
        }))

        // Sort tasks and lists
        return tasks.concat(lists).sort((a, b) => a.order - b.order)
      },
      pickNewProfilePic: function (input) {
        if (input && input.target && input.target.files && input.target.files.length > 0) {
          if (input.target.files.length > 1) {
            alert('Please choose only one image file')
          } else {
            const ftype = input.target.files[0].name.toLowerCase().split('.').pop()
            if (!(ftype === 'png' || ftype === 'jpg' || ftype === 'jpeg' || ftype === 'tif' || ftype === 'gif')) {
              alert('Please choose a valid image file (.jpg, .png, .tif, .gif, etc)')
            } else {
              this.$root.state.uploadingProfilePic = true
              document.getElementById('profilePicImg').src = ''
              const fileRef = ref(this.$root.storage, `profilePics/${this.$root.currentUser.uid}.${ftype}`)
              console.log('Uploading', input.target.files[0].name)
              const app = this
              uploadBytesResumable(fileRef, input.target.files[0])
                .then(function (snapshot) {
                  getDownloadURL(snapshot.ref).then(function (downloadURL) {
                    app.$set(app.$root.userProfile, 'profilePic', downloadURL)
                  })
                })
            }
          }
        }
      },
    },
  }
</script>

<style>
  .tasks-badge .v-badge__badge {
    color: var(--headerColor);
    font-weight: bolder;
    height: 16px;
    min-width: 16px;
    padding-top: 2px;
    padding-left: 5px;
  }
</style>

<style scoped>
  .cart-menu-content {
    height: inherit;
  }
  .navigation-list ::v-deep(.v-list-group__items) {
    overflow: hidden;
  }

  .navigation-list ::v-deep(.v-list-group__header) {
    padding-left: 16px !important;
  }

  .navigation-list-subgroup ::v-deep(.v-list-item__icon) {
    margin: 6px 0;
  }

  .navigation-list-subgroup ::v-deep(.v-list-group__header) {
    min-height: 0;
    margin-bottom: 0 !important;
  }

  #user-header-icon {
    margin-top: -3px;
  }

  #search-wrapper {
  background-color: var(--headerColor);
  width: 302px;
  margin: 8px;
  border-radius: 17px;
  overflow: hidden;
  transition: 0.3s;
  height: fit-content;
  z-index: 1;
}

#search-query {
  padding: 0 0 0 8px;
  font-weight: lighter;
  font-size: 20px;
  height: 34px;
  background-color: transparent;
  border: none;
  outline: none;
  text-align: left;
  width: 100%;
  box-sizing: border-box;
  padding-right: 30px;
  text-overflow: ellipsis;
}

#search-query:focus + #search-results {
  display: inherit;
}

#search-results {
  transition: 0.3s;
  height: 100%;
  max-height: 90vh;
  overflow: auto;
  width: 100%;
  padding-bottom: 1px;
}

.stock #logo {
  padding-top: 4px;
  background-image: none;
  position: relative;
  width: 190px;
  height: 50px;
  padding-left: 28px;
  background-repeat: no-repeat;
  background-size: contain;
  margin-left: var(--logoLeftMargin);
  float: left;
  color: white;
  text-align: center;
  text-decoration: none;
  font-size: 18px;
  line-height: 25px;
  border-radius: 4px;
}

.header-wrapper {
  width: 1000px;
  margin: auto;
  display: flex;
}

.header a.logo {
  font-size: 25px;
  font-weight: bold;
}

.header a:hover, .favorites-icon:hover {
  color: var(--highlightColor);
}

.header a.active {
  color: var(--highlightColor);
}

.header-right {
  flex-grow: 1;
  display: flex;
}

.header-right a, .favorites-icon {
  transition: 0.2s;
}

.header-left {
  margin: 0;
}

.header-left a {
  position: relative;
  height: 100%;
  margin-top: 4px;
  margin-left: 4px;
  float: left;
}

.header-left > a {
  padding: 8px;
  font-size: 12px important!;
  transition: 0.2s;
}

.userProfile {
  margin: 32px 24px;
}

.setting {
  margin: 8px 24px;
}

.settings-pane {
  top: calc(50px + env(safe-area-inset-top));
  width: 260px;
  background-color: var(--headerColor);
  height: calc(100% - 50px - env(safe-area-inset-top));
  position: fixed;
  box-shadow: #0005 0 0 20px 0px;
  right: -270px;
  transition: 0.5s;
  z-index: 999;
  display: flex;
  flex-direction: column;
}

.settings-pane.active {
  right: 0px;
}

.click-off-pane {
  top: calc(50px + env(safe-area-inset-top));
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 400;
  background-color: var(--headerColor);
  transition: 0.5s;
  opacity: 0;
  pointer-events: none;
  z-index: 1;
}

.click-off-pane.active {
  opacity: 0.8;
  pointer-events: auto;
}

.profilePic {
  display: flex;
  margin: 8px auto;
  position: relative;
  overflow: hidden;
  height: 80px;
  width: 80px;
  border-radius: 50px;
  cursor: pointer;
  background-size: cover;
}

.profilePic img {
  object-fit: cover;
  display: block;
  background-size: cover;
  background-position: center;
  min-width: 100%;
  min-height: 100%;
  z-index: 2;
}

.profilePicUploader {
  visibility: hidden;
}

.editIcon {
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  opacity: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 22px;
  color: white;
  background-color: #0004;
  cursor: pointer;
  transition: 0.3s;
  z-index: 3;
}

.userProfile .fullName {
  text-align: center;
  width: 100%;
  box-sizing: border-box;
  padding: 4px;
  background-color: transparent;
  border: none;
  font-size: 18px;
  color: white;
}

.userEmail {
  margin: 0;
  font-size: 12px;
  text-align: center;
}

.profilePic:hover .editIcon {
  opacity: 1;
}

/*Simple css to style it like a toggle switch*/
.theme-switch-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.theme-switch-wrapper h4 {
  margin: 0 0 0 6px;
  font-size: 1rem;
  color: white;
}

.theme-switch {
  display: inline-block;
  height: 30px;
  position: relative;
  width: 50px;
}

.theme-switch-select {
  width: 90px;
  display: inline-block;
  height: 30px;
  position: relative;
  background-color: white;
  border-radius: 8px;
  color: black;
  text-align: right;
  padding: 4px;
  -webkit-appearance: auto;
}

.theme-switch input {
  display: none;
}

.slider {
  background-color: #ccc;
  bottom: 0;
  cursor: pointer;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: 0.4s;
}

.slider:before {
  background-color: #fff;
  bottom: 4px;
  content: "";
  height: 22px;
  left: 4px;
  position: absolute;
  transition: 0.4s;
  width: 22px;
}

input:checked + .slider {
  background-color: #66bb6a;
}

input:checked + .slider:before {
  transform: translateX(20px);
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.profile-spinner {
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  color: white;
}

.header {
  display: flex;
  height: 50px;
  margin-top: env(safe-area-inset-top);
  border-top-left-radius: min(16px, env(safe-area-inset-top));
  border-top-right-radius: min(16px, env(safe-area-inset-top));
  width: 100%;
  transition: 0.1s;
  z-index: 60;
}
</style>
